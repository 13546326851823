import React from "react";

// import Off20 from "../../assets/ate-20-off.png";
// import Off40 from "../../assets/ate-40-off.png";
// import Box04 from "../../assets/box-04.png";
// import Off50 from "../../assets/ate-50-off.png";

import Box24 from "../../assets/box-24.png";
import Box25 from "../../assets/box-25.png";
import Box26 from "../../assets/box-26.png";
import Box27 from "../../assets/box-27.png";

import {
  Box,
  BoxBtn,
  BoxSubTitle,
  BoxTitle,
  Container,
  Content,
  TextLegal,
  ButtonCadastro,
  Title,
  TitleBold,
  MoreInfo,
} from "./styles";

export default function Pacotes() {
  return (
    <Container>
      <Title>
        Pacotes com descontos <TitleBold>por tempo limitado</TitleBold>
      </Title>
      <Content>
        <Box className="wow fadeIn" data-wow-delay="400ms">
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box26} alt="Natal" />
          <BoxTitle>Seu Natal deve ser incrível</BoxTitle>
          <BoxSubTitle>
            Venha celebrar essa noite especial em nosso Resort Fazenda.
          </BoxSubTitle>
          <MoreInfo href="pacotes/natal">O que tem neste pacote</MoreInfo>
          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
        <Box className="wow fadeIn" data-wow-delay="400ms">
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box25} alt="Box" />
          <BoxTitle>Para começar 2025 muito bem!</BoxTitle>
          <BoxSubTitle>
            Comemore a virada de ano com uma festa incrível e muita diversão
          </BoxSubTitle>
          <MoreInfo href="pacotes/reveillon">O que tem neste pacote</MoreInfo>
          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
        <Box className="wow fadeIn" data-wow-delay="300ms">
          {/* <img className="selo" src={Off40} alt="Selo" /> */}
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box27} alt="Box" />
          <BoxTitle>Férias no 3 Pinheiros</BoxTitle>
          <BoxSubTitle>
            Aproveite as férias de Janeiro para relaxar no melhor clima da
            fazenda.
          </BoxSubTitle>
          <MoreInfo href="pacotes/feriados">O que tem neste pacote</MoreInfo>

          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
      </Content>
      <ButtonCadastro
        target="_blank"
        href="http://3pinheiros.com.br/cadastro/index.html">
        Cadastre-se e fique por dentro de nossas promoções e sorteios
      </ButtonCadastro>
      {/* <TextLegal>
        *Válido apenas para baixa temporada. Não é válido para feriados.
      </TextLegal> */}
      <Content />
    </Container>
  );
}
