import React from "react";
import { Player, BigPlayButton } from "video-react";
import { Producao, Imagem } from "./styles";

import PO_00 from "../../assets/gastronomia/producao/vegetables.png";
import PO_01 from "../../assets/gastronomia/producao/food.png";
import PO_02 from "../../assets/gastronomia/producao/bread-baguette-outline.png";

import ImagemVideoGastronomia from "../../assets/gastronomia/videoplayer_gastronomia.jpg";
// import VideoPlayer from "../../assets/videos/video_gastronomia.mp4";

function CompontentProducao({ displayBGTop }) {
  return (
    <Producao displayBGTop={displayBGTop}>
      <span />

      <Imagem className="imagem-video">
        {/* <Player
          // playsInline
          poster={ImagemVideoGastronomia}
          src={VideoPlayer}
          width="100%"
          height="100%">
          <BigPlayButton display="none" position="center" />
        </Player> */}
      </Imagem>

      <div>
        <img src={PO_00} />
        <p>
          Aqui todas as saladas são cultivadas na
          <br /> horta orgânica da Fazenda 3 Pinheiros,
          <br /> uma verdadeira experiência
          <br /> gastronômica orgânica.
        </p>
      </div>

      <div>
        <img src={PO_01} />
        <p>
          Nossos Queijos são produzidos em
          <br /> nossa queijaria, com Leite ordenhado
          <br />
          na própria fazenda. Outros derivados
          <br /> como manteiga, requeijão, iogurte,
          <br /> entre outros, também são orgânicos e<br /> produzidos pelo
          Hotel.
        </p>
      </div>

      <div>
        <img src={PO_02} />
        <p>
          Todos os pães, bolos, broas, pães
          <br /> de queijo e massas são artesanais e<br />
          produzidos na padaria do Hotel.
        </p>
      </div>
    </Producao>
  );
}

export default CompontentProducao;
