import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Hospedagem from "../../components/Hospedagem";
import Informacoes from "../../components/Informacoes";
import Quartos from "../../components/Quartos";
import Menu from "../../components/Menu";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile";
import Internet from "../../components/InternetInfo";
import Banner from "../../components/Acomodacoes/Banner/";
import Check from "../../components/Acomodacoes/Check/";

export default function PaginaAcomodacoes() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Acomodações - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      {/* <Reserva /> */}
      <NewReserva />

      {/* <TopInterna
        titulo="Nossas"
        titulo2="Acomodações"
        subtitulo="Apartamentos"
        bgColor="#262C40"
        fontColor="#fff"
        texto="Apartamentos que misturam a rusticidade da fazenda com toda a elegância e conforto que nossos hospedes merecem."
      /> */}
      <Banner />
      <Check />
      <ReservaMobile bgColor="#262C40" />
      <Quartos />
      <Hospedagem />
      <Informacoes />
    </>
  );
}
