import React, { useState, useEffect } from "react";
import * as S from "./styles";

const AvisoReserva = () => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      window.location.href = "https://book.omnibees.com/hotel/6628";
    }
  }, [countdown]);

  return (
    <S.Container>
      <S.FakeModal>
        <S.Box>
          <h1>Aviso Importante</h1>
          <p>Não aceitamos pagamentos com cartões de crédito de terceiros.</p>
          <p>
            O titular do cartão de crédito deve ser um dos hóspedes da reserva
            com comprovação no momento do checkin
          </p>
          <p className="destaque">
            (apresentação do cartão e documento comprovando a identidade.)
          </p>
          <p>ou o pagamento não será válido</p>
          <p>Gratuidade para dois filhos até 12 anos.</p>
          <S.BtnCount
            onClick={() => {
              window.location.href = "https://book.omnibees.com/hotel/6628";
            }}>
            Avançar {countdown > 0 ? `(${countdown})` : ""}
          </S.BtnCount>
          <img
            className="top-left"
            src={require("../../assets/ornamentos/top-left.png")}
            alt="ornament"
          />
          <img
            className="top-right"
            src={require("../../assets/ornamentos/top-right.png")}
            alt="ornament"
          />
          <img
            className="bottom-left"
            src={require("../../assets/ornamentos/bottom-lef.png")}
            alt="ornament"
          />
          <img
            className="bottom-right"
            src={require("../../assets/ornamentos/bottom-right.png")}
            alt="ornament"
          />
          <img
            className="logo"
            src={require("../../assets/ornamentos/logo.png")}
            alt="logo"
          />
        </S.Box>
      </S.FakeModal>
    </S.Container>
  );
};

export default AvisoReserva;
